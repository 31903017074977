import { FC } from "react";
import Image from "next/image";
import { ComponentTestimonialFragment } from "contentful/gql/graphql";
import { assetFragment, entityCompanyFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";

const SwitchbackTestimonial = ({ quote, person }: ComponentTestimonialFragment) => {
  const personImage = getFragmentData(assetFragment, person?.headshot);
  const company = getFragmentData(entityCompanyFragment, person?.company);
  const companyLogo = getFragmentData(assetFragment, company?.logoIcon);

  return (
    <div className="border-gray-200 mt-8 border-t pt-6">
      <blockquote>
        <div>
          <p className="text-base ">&ldquo;{quote}&rdquo;</p>
        </div>
        <footer className="mt-3">
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              {personImage?.url ? (
                <Image
                  className="h-md w-md rounded-full"
                  src={personImage?.url}
                  alt={personImage?.title || "Testimonial profile photo"}
                  height={personImage.height ?? 20}
                  width={personImage.width ?? 20}
                />
              ) : (
                <span className="bg-gray-100 inline-block h-14 w-14 overflow-hidden rounded-full">
                  <svg className="h-full w-full " fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
            </div>
            <div className="text-base font-medium ">
              {person?.name}, {person?.role}
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  );
};

export default SwitchbackTestimonial;
