import { ComponentTestimonialFragment } from "contentful/gql/graphql";
import SwitchbackTestimonial from "./SwitchbackTestimonial";
import ScrollInteractiveTestimonial from "./ScrollInteractiveTestimonial";
import LargeTestimonial from "./LargeTestimonial";

const TestimonialV2 = (props: ComponentTestimonialFragment) => {
  const variant = props.variant ? props.variant : "Scroll Interactive"; // 'Scroll Interactive' | 'Large' | 'Compact'

  switch (variant) {
    case "Large":
      return <LargeTestimonial {...props} />;
    case "Compact":
      return <SwitchbackTestimonial {...props} />;
    case "Scroll Interactive":
      return <ScrollInteractiveTestimonial {...props} />;
  }
};

export default TestimonialV2;
