import Image from "next/image";
import ColorizedText from "components/ColorizedText";
import richTextParser, { RichText } from "utils/richTextParser";
import { ComponentHeadingFragment } from "contentful/gql/graphql";
import { getFragmentData } from "contentful/gql";
import { assetFragment, ctaFragment } from "contentful/fragments";
import CtaLink from "components/CtaLink";
import typography from "components/typography";
import { cn } from "utils/functions";
import HeadingText from "./HeadingText";
import Icon from "components/Icon";

const ComponentHeading = ({
  heading,
  headingType,
  alignment,
  callToActionCollection,
  body,
  eyebrow,
  eyebrowIcon: eyebrowIconFragment,
  pinnedContainer,
  width,
}: ComponentHeadingFragment & { pinnedContainer?: any }) => {
  const eyebrowIcon = getFragmentData(assetFragment, eyebrowIconFragment);

  const flexAlign =
    alignment?.toLowerCase() === "center"
      ? "items-center mx-auto"
      : alignment?.toLowerCase() === "left"
        ? "items-start mr-auto"
        : "items-end ml-auto";

  const textAlign =
    alignment?.toLowerCase() === "center"
      ? "text-center"
      : alignment?.toLowerCase() === "left"
        ? "text-left"
        : "text-right";

  return (
    <div
      className={cn(
        "flex flex-col text-primary-dark-800 dark:text-primary-neutral-50",
        flexAlign,
        width?.toLowerCase() === "wide"
          ? ""
          : "max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl",
      )}
    >
      {eyebrow || eyebrowIcon?.url ? (
        <div className="mb-2xs flex items-center">
          {eyebrowIcon?.url && (
            <>
              {eyebrowIcon.url.endsWith(".svg") ? (
                <span className="mr-3xs inline-flex h-lg w-lg items-center justify-center rounded-full bg-primary-light-50 p-2xs text-primary-light-500">
                  <Icon url={eyebrowIcon.url} />
                </span>
              ) : (
                <span className="mb-xs inline-flex h-lg">
                  <Image
                    src={eyebrowIcon.url}
                    height={74}
                    width={192}
                    className="h-full w-full object-contain"
                    alt="Eyebrow icon"
                  />
                </span>
              )}
            </>
          )}
          {eyebrow && (
            <div className={`font-display text-lg font-extrabold text-primary-light-500 lg:text-xl`}>{eyebrow}</div>
          )}
        </div>
      ) : null}

      {heading && (
        <HeadingText
          heading={heading}
          pinnedContainer={pinnedContainer}
          headingType={headingType}
          textAlign={textAlign}
        />
      )}
      {body && (
        <div
          className={cn(
            typography.body.componentBody,
            textAlign,
            "mt-3xs text-primary-dark-800/70 dark:text-primary-neutral-50/80 lg:mt-2xs 2xl:mt-xs",
            width?.toLowerCase() === "wide" ? "" : "max-w-[35em] lg:max-w-[40em] 2xl:max-w-[45em]",
          )}
        >
          {richTextParser(body as RichText)}
        </div>
      )}
      {!!callToActionCollection?.items?.length && (
        <div className={"mt-2xs flex gap-sm md:mt-sm 2xl:mt-md"}>
          {callToActionCollection.items.map((item) => {
            const callToAction = getFragmentData(ctaFragment, item);
            return callToAction && <CtaLink {...callToAction} key={callToAction?.sys?.id} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ComponentHeading;
